import React from "react"
import styled from "styled-components"

import AppLayout from "@components/layout/App"

import SvgManagement from "@assets/career-levels/management.inline.svg"
import SvgProfessionalConsultancy from "@assets/career-levels/professional-consultancy.inline.svg"
import SvgBusinessSupport from "@assets/career-levels/business-support.inline.svg"

const Content = styled.div`
  padding: 1rem 1.5rem;
`

const HeaderFlex = styled.div`
  display: flex;

  padding-bottom: 24px;

  h1 {
    flex-grow: 5;
  }
`

const ImageDiv = styled.div`
  overflow: hidden;

  // Remove the space between <img> https://stackoverflow.com/a/35216227
  font-size: 0;
`

const Page = props => {
  const { pageContext } = props

  const renderSvg = () => {
    if (pageContext.slug === "management") {
      return <SvgManagement />
    } else if (pageContext.slug === "professional-consultancy") {
      return <SvgProfessionalConsultancy />
    } else if (pageContext.slug === "business-support") {
      return <SvgBusinessSupport />
    }
  }

  return (
    <AppLayout>
      <Content>
        <HeaderFlex>
          <h1>{pageContext.name}</h1>
        </HeaderFlex>
        <ImageDiv>{renderSvg()}</ImageDiv>
      </Content>
    </AppLayout>
  )
}

export default Page
